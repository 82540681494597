import React, { memo } from 'react'

import GetErrorMessage from '../utils/error-messages-util'
import '../styles/Error.scss'
import { ErrorPropsType } from '../types/Errors'

function Error(props: ErrorPropsType) {
  const { type, title, message } = GetErrorMessage(props)
  return (
		<section className="error">
			<header>
				{type &&
					<h2 className="error-type">{type}</h2>
				}
				<h3 className="error-title">{title}</h3>
			</header>
			<div className="error-description">
				<p>{message}</p>
			</div>
		</section>
  )
}

export default memo(Error)
