import React, {
  FC,
  createContext,
  useReducer,
  useContext,
} from 'react'

import { AppReducerAction, AppState, GlobalAppReducer } from './types'
import defaultState from './state'

type StoreContext = [AppState, React.Dispatch<AppReducerAction>]

export const Store = createContext<StoreContext>([defaultState, () => { }])

export const useStore = () => useContext<StoreContext>(Store)

type Props = {
  children: JSX.Element[] | JSX.Element;
  initialState: AppState;
  reducer: GlobalAppReducer;
}

const StoreProvider: FC<Props> = ({
  children,
  initialState,
  reducer,
}: Props) => {
  const [store, dispatch] = useReducer(reducer, initialState)

  return <Store.Provider value={[store, dispatch]}>{children}</Store.Provider>
}

export default StoreProvider
