import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'


import LanguageDetector from 'i18next-browser-languagedetector'

import basicDeAT from './utils/translations/de-AT/basic.json'
import errorDeAT from './utils/translations/de-AT/error-message-utils.json'
import componentsDeAT from './utils/translations/de-AT/components-utils.json'

import basicDeCH from './utils/translations/de-CH/basic.json'
import errorDeCH from './utils/translations/de-CH/error-message-utils.json'
import componentsDeCH from './utils/translations/de-CH/components-utils.json'

import basicEnIE from './utils/translations/en-IE/basic.json'
import errorEnIE from './utils/translations/en-IE/error-message-utils.json'
import componentsEnIE from './utils/translations/en-IE/components-utils.json'

import basicEsES from './utils/translations/es-ES/basic.json'
import errorEsES from './utils/translations/es-ES/error-message-utils.json'
import componentsEsES from './utils/translations/es-ES/components-utils.json'

import basicFrBE from './utils/translations/fr-BE/basic.json'
import errorFrBE from './utils/translations/fr-BE/error-message-utils.json'
import componentsFrBE from './utils/translations/fr-BE/components-utils.json'

import basicFrCH from './utils/translations/fr-CH/basic.json'
import errorFrCH from './utils/translations/fr-CH/error-message-utils.json'
import componentsFrCH from './utils/translations/fr-CH/components-utils.json'

import basicFrFR from './utils/translations/fr-FR/basic.json'
import errorFrFR from './utils/translations/fr-FR/error-message-utils.json'
import componentsFrFR from './utils/translations/fr-FR/components-utils.json'

import basicFrLU from './utils/translations/fr-LU/basic.json'
import errorFrLU from './utils/translations/fr-LU/error-message-utils.json'
import componentsFrLU from './utils/translations/fr-LU/components-utils.json'

import basicItIT from './utils/translations/it-IT/basic.json'
import errorItIT from './utils/translations/it-IT/error-message-utils.json'
import componentsItIT from './utils/translations/it-IT/components-utils.json'

import basicNlBE from './utils/translations/nl-BE/basic.json'
import errorNlBE from './utils/translations/nl-BE/error-message-utils.json'
import componentsNlBE from './utils/translations/nl-BE/components-utils.json'

import basicNnNO from './utils/translations/nn-NO/basic.json'
import errorNnNO from './utils/translations/nn-NO/error-message-utils.json'
import componentsNnNO from './utils/translations/nn-NO/components-utils.json'

import basicSvSE from './utils/translations/sv-SE/basic.json'
import errorSvSE from './utils/translations/sv-SE/error-message-utils.json'
import componentsSvSE from './utils/translations/sv-SE/components-utils.json'

import basicEnUS from './utils/translations/en-US/basic.json'
import errorEnUS from './utils/translations/en-US/error-message-utils.json'
import componentsEnUS from './utils/translations/en-US/components-utils.json'

import basicCaES from './utils/translations/ca-ES/basic.json'
import errorCaES from './utils/translations/ca-ES/error-message-utils.json'
import componentsCaES from './utils/translations/ca-ES/components-utils.json'

import basicDeDE from './utils/translations/de-DE/basic.json'
import errorDeDE from './utils/translations/de-DE/error-message-utils.json'
import componentsDeDE from './utils/translations/de-DE/components-utils.json'

import basicDaDK from './utils/translations/da-DK/basic.json'
import errorDaDK from './utils/translations/da-DK/error-message-utils.json'
import componentsDaDK from './utils/translations/da-DK/components-utils.json'

import basicEnGB from './utils/translations/en-GB/basic.json'
import errorEnGB from './utils/translations/en-GB/error-message-utils.json'
import componentsEnGB from './utils/translations/en-GB/components-utils.json'

import basicNlNL from './utils/translations/nl-NL/basic.json'
import errorNlNL from './utils/translations/nl-NL/error-message-utils.json'
import componentsNlNL from './utils/translations/nl-NL/components-utils.json'

const defaultNamespace = 'basic'
const otherNamespaces = ['error', 'components']


let detectionOrder = ['querystring', 'navigator']
if (process.env.REACT_APP_I18N_QUERYSTRING === 'false') {
  detectionOrder = detectionOrder.filter(filter => filter !== 'querystring')
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
      bindI18nStore: 'added',
    },
    backend: { loadPath: '/translations/{{lng}}/{{ns}}.json' },
    detection: {
      order: detectionOrder,

      lookupQuerystring: 'lng',
      lookupCookie: '',
      lookupLocalStorage: '',
      lookupSessionStorage: '',

      caches: [''],
    },
    resources: {
      'en-US': {
        basic: basicEnUS,
        error: errorEnUS,
        components: componentsEnUS,
      },
      'ca-ES': {
        basic: basicCaES,
        error: errorCaES,
        components: componentsCaES,
      },
      'de-DE': {
        basic: basicDeDE,
        error: errorDeDE,
        components: componentsDeDE,
      },
      'da-DK': {
        basic: basicDaDK,
        error: errorDaDK,
        components: componentsDaDK,
      },
      'en-GB': {
        basic: basicEnGB,
        error: errorEnGB,
        components: componentsEnGB,
      },
      'nl-NL': {
        basic: basicNlNL,
        error: errorNlNL,
        components: componentsNlNL,
      },
      'de-AT': {
        basic: basicDeAT,
        error: errorDeAT,
        components: componentsDeAT,
      },
      'de-CH': {
        basic: basicDeCH,
        error: errorDeCH,
        components: componentsDeCH,
      },
      'en-IE': {
        basic: basicEnIE,
        error: errorEnIE,
        components: componentsEnIE,
      },
      'es-ES': {
        basic: basicEsES,
        error: errorEsES,
        components: componentsEsES,
      },
      'fr-BE': {
        basic: basicFrBE,
        error: errorFrBE,
        components: componentsFrBE,
      },
      'fr-CH': {
        basic: basicFrCH,
        error: errorFrCH,
        components: componentsFrCH,
      },
      'fr-FR': {
        basic: basicFrFR,
        error: errorFrFR,
        components: componentsFrFR,
      },
      'fr-LU': {
        basic: basicFrLU,
        error: errorFrLU,
        components: componentsFrLU,
      },
      'it-IT': {
        basic: basicItIT,
        error: errorItIT,
        components: componentsItIT,
      },
      'nl-BE': {
        basic: basicNlBE,
        error: errorNlBE,
        components: componentsNlBE,
      },
      'nn-NO': {
        basic: basicNnNO,
        error: errorNnNO,
        components: componentsNnNO,
      },
      'sv-SE': {
        basic: basicSvSE,
        error: errorSvSE,
        components: componentsSvSE,
      },
    },
    fallbackLng: {
      'en': ['en-US'],
      'ca': ['ca-ES', 'en-US'],
      'de': ['de-DE', 'en-US'],
      'da': ['da-DK', 'en-US'],
      'nl': ['nl-NL', 'en-US'],
      'es': ['es-ES', 'en-US'],
      'fr': ['fr-FR', 'en-US'],
      'it': ['it-IT', 'en-US'],
      'nn': ['nn-NO', 'en-US'],
      'sv': ['sv-SE', 'en-US'],
      'default': ['en-US'],
    },
    supportedLngs: [
      'en-US',
      'en-GB',
      'en-IE',
      'en',
      'ca-ES',
      'ca',
      'de-DE',
      'de-AT',
      'de-CH',
      'de',
      'da-DK',
      'da',
      'nl-NL',
      'nl-BE',
      'nl',
      'es-ES',
      'es',
      'fr-FR',
      'fr-BE',
      'fr-CH',
      'fr-LU',
      'fr',
      'it-IT',
      'it',
      'nn-NO',
      'nn',
      'sv-SE',
      'sv',
    ],
    debug: true,
    ns: otherNamespaces.concat(defaultNamespace),
    defaultNS: defaultNamespace,
    returnEmptyString: false,
    returnNull: false,
  })
export default i18n