import React, { memo } from 'react'
import PropTypes from 'prop-types'

import '../styles/HolderIssueMessage.scss'
import { HolderIssueMessageType } from '../types/Components'

function HolderIssueMessage({ selector, message }: HolderIssueMessageType) {
  return (
		<p className={`holder-card-content-issue-message ${selector ? `holder-card-content-issue-message${selector}` : ''}`}>{message}</p>
  )
}

export default memo(HolderIssueMessage)
HolderIssueMessage.defaultProps = {}
HolderIssueMessage.propTypes = {
  message: PropTypes.string.isRequired,
  selector: PropTypes.string.isRequired,
}
