import { ErrorPropsType } from '../types/Errors'

export enum ReducerType {
  CLOSE_ACTION = 'CLOSE_ACTION',
  REFRESH_ACTION = 'REFRESH_ACTION',
  SAVE_CREW_APP_TOKEN = 'SAVE_CREW_APP_TOKEN',
  ERROR_ACTION = 'ERROR_ACTION',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  UPDATE_OUTLET_ACTION = 'UPDATE_OUTLET_ACTION',
  UPDATE_TOKEN_ACTION = 'UPDATE_TOKEN_ACTION',
}

export interface AppReducerAction {
  type: ReducerType
  [key: string]: unknown
}

export type AppState = {
  error: ErrorPropsType | null
  loading: boolean
  token: string
  outletId: string
  crewAppToken: string
  connectionClosed: boolean
  [key: string]: unknown
}

export type GlobalAppReducer = (state: AppState, action: AppReducerAction ) => AppState
