import {
  ErrorPropsType,
  DispenserViewErrorTypeEnum,
  ComponentErrorPropsType,
  ErrorTypesType,
} from '../types/Errors'

import { getComponentError } from './error-codes'
import ErrorMessage from './error-message'

export const NO_STATUS_CODE = 'none'

export const getErrorType = (message: string): ErrorTypesType | null => {
  const messageExp = message.match( /\d{3}/g)
  const statusCode = messageExp?.length && messageExp[0]
  const statusCodeList = [
    '401',
    '404',
    '500',
  ]
  if (!!statusCode && statusCodeList.includes(statusCode)) {
    return statusCode as ErrorTypesType
  }
  return null
}

const GetErrorMessage = ({ type, component, variables }: ErrorPropsType) => {
  const componentError = getComponentError(component, type)

  const componentErrorProps:ComponentErrorPropsType = {
    type: type === DispenserViewErrorTypeEnum.ERROR_NO_STATUS_CODE ? '' : type.toString(),
  }

  if (componentError) {
    const errorsMessage = new ErrorMessage(componentError.title, componentError.message, variables)
    componentErrorProps.title = errorsMessage.title
    componentErrorProps.message = errorsMessage.message
  }

  return componentErrorProps
}

export default GetErrorMessage