type ContainsValueFn =
  <EnumType extends {}, ValueType = string>(EnumType:EnumType, value:ValueType) => boolean

const containsValue:ContainsValueFn = (EnumType, ValueType) => {
  return Object.values(EnumType).includes(ValueType)
}

const Enum = {
  containsValue,
}

export default Enum
export type { ContainsValueFn }