import React, { memo } from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import ContentLoader from 'react-content-loader'

import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import CaretRight from '../images/caret-right.svg'
import { getDispenserImage, getDispenserType } from '../utils/dispenser-type-util'
import LoaderConfig from '../utils/loader-default-config'

import '../styles/DispenserCard.scss'
import { DispenserType } from '../types/Components'

interface DispenserCardPropsType {
  dispenser: DispenserType,
  loading: boolean
}

const getCardMessage = (t:Function, dispenser:DispenserType):string => {
  return dispenser?.crewSupport ? t('basic:clickTodetails') : t('basic:dataNotAvailable')
}

function DispenserCard({ dispenser, loading }: DispenserCardPropsType) {
  const { t } = useTranslation()
  const match = useRouteMatch()

  const { crewSupport, type, serialNumber, dispenserName } = dispenser
  const dispenserImage = getDispenserImage(type)
  const dispenserType = getDispenserType(type)
  const dispenserCardName = dispenserName || serialNumber
  const cardMessage = getCardMessage(t, dispenser)

  return (
    <article className={`dispenser-card ${loading ? 'dispenser-card--loading' : ''}`}>
      <header className="dispenser-card-header">
        {loading &&
          <ContentLoader
            viewBox="0 0 343 56"
            speed={2}
            {...LoaderConfig}>
            <rect x="56" y="24" rx="2" ry="2" width="247" height="8" />
            <circle cx="28" cy="28" r="12" />
          </ContentLoader>
        }
        {!loading &&
          <>
            <div className={`dispenser-card-status dispenser-card-status${crewSupport ? '--normal' : '--not-available'}`} />
            <h4 className="dispenser-card-name">{dispenserCardName}</h4>
            {crewSupport &&
              <nav>
                <Link to={{
                  pathname: `${match.url}/dispenser/${serialNumber}`,
                  state: { dispenserName },
                }}>
                  <img src={CaretRight} className="dispenser-card-arrow" alt={t('basic:clickForDetails', { serialNumber })} />
                </Link>
              </nav>
            }
          </>
        }
      </header>
      <section className="dispenser-card-content">
        {!loading &&
          <>
            <summary className="dispenser-card-content-item dispenser-card-content-summary">
              <p>{cardMessage}</p>
            </summary>
            <aside className="dispenser-card-content-item dispenser-card-content-aside">
              {dispenserImage &&
                <img src={dispenserImage} alt={t('basic:thisIsA', { dispenserType })} />
              }
            </aside>
          </>
        }
        {loading &&
          <ContentLoader
            viewBox="0 0 343 176"
            speed={2}
            {...LoaderConfig}>
            <rect x="48" y="27" rx="1" ry="1" width="181" height="4" />
            <circle cx="24" cy="29" r="8" />
            <rect x="48" y="57" rx="1" ry="1" width="181" height="4" />
            <circle cx="24" cy="59" r="8" />
          </ContentLoader>
        }
      </section>
    </article>
  )
}

export default memo(DispenserCard)

DispenserCard.defaultProps = {
  dispenser: {
    type: '',
    crewSupport: false,
    serialNumber: '',
    dispenserName: '',
  },
  loading: false,
}

DispenserCard.propTypes = {
  dispenser: PropTypes.shape({
    type: PropTypes.string,
    crewSupport: PropTypes.bool,
    serialNumber: PropTypes.string,
    dispenserName: PropTypes.string,
  }),
  loading: PropTypes.bool,
}