/* eslint-disable import/prefer-default-export */
import initialState from '../../state'
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'

export class RefreshActionReducer extends AppReducer {
  newState(): AppState {
    return {
      ...initialState,
    }
  }
}

export function refreshAction(): AppReducerAction {
  return { type: ReducerType.REFRESH_ACTION }
}