/* eslint-disable import/prefer-default-export */
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'

export class CloseActionReducer extends AppReducer {
  newState(state: AppState): AppState {
    return {
      ...state,
      loading: false,
      connectionClosed: true,
    }
  }
}

export function closeAction(): AppReducerAction {
  return { type: ReducerType.CLOSE_ACTION }
}