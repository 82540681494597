import React, { memo, useEffect, useState } from 'react'
import { Route, useParams } from 'react-router-dom'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { GET_DISPENSER_STATE_FOR_DISPENSER } from '../utils/queries'
import { dispenserDashboard } from '../utils/routes'
import { getErrorType, NO_STATUS_CODE } from '../utils/error-messages-util'
import Error from './Error'
import HolderList from './HolderList'
import FloatingButton from './FloatingButton'
import '../styles/DispenserView.scss'
import { ReactComponent as RefreshIcon } from '../images/refresh-icon.svg'
import { ErrorPropsType } from '../types/Errors'

const getComponentError = (
  loading: boolean,
  error: ApolloError | undefined,
  isValidDisp: boolean,
  serialNumber: string,
): ErrorPropsType | null => {
  let componentError = null
  if (!loading && !error && !isValidDisp) {
    componentError = {
      type: NO_STATUS_CODE,
      component: 'dispenserView',
      variables: { serialNumber },
    }
  } else if (!loading && error) {
    componentError = {
      type: getErrorType(error.message) || '500',
      component: 'dispenserView',
      variables: { message: error.message },
    }
  }
  return componentError as ErrorPropsType
}

function DispenserView() {
  const { serialNumber } = useParams<{ serialNumber: string }>()
  const [firstLoad, setFirstLoad] = useState(false)
  const [getDispenser, { loading, data, error, refetch }] = useLazyQuery(
    GET_DISPENSER_STATE_FOR_DISPENSER,
    {
      variables: {
        serialNumber,
      },
      nextFetchPolicy: 'cache-and-network',
    },
  )

  const isValidDisp = Boolean(
    data &&
    data.getDispenser &&
    (data.getDispenser.serialNumber || data.getDispenser.holders),
  )
  const dispenser = isValidDisp ? data.getDispenser : {}
  const isComponentLoading = !(firstLoad && !loading)

  const componentError = getComponentError(isComponentLoading, error, isValidDisp, serialNumber)

  useEffect(() => {
    setFirstLoad(true)
    getDispenser()
  }, [getDispenser, setFirstLoad])

  const refreshDispenserData = () => {
    if (!firstLoad) return
    refetch()
  }

  return (
		<section className="dispenser-view">
			<FloatingButton onClick={refreshDispenserData}>
				<RefreshIcon/>
			</FloatingButton>

			{!componentError &&
				// eslint-disable-next-line react/jsx-no-bind
				<Route exact path={dispenserDashboard} render={(props) =>
					<HolderList {...props} holders={dispenser.holders} loading={isComponentLoading} />
				} />
			}
			{componentError &&
				<Error {...componentError} />
			}
		</section>
  )
}

export default memo(DispenserView)
DispenserView.propTypes = {}
