import React, { memo } from 'react'
import PropTypes from 'prop-types'

import '../styles/FloatingButton.scss'

function FloatingButton({ children, onClick }) {
  return (
		<button className="floating-button" type="button" onClick={onClick}>
			{ children }
		</button>
  )
}

export default memo(FloatingButton)
FloatingButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}
