const colors: object = {
  'red': [0, 5],
  'yellow': [6, 19],
  'green': [20, 100],
}

type ObjectKey = keyof typeof colors

const isInInterval = (percentage:number, interval:number[]) => {
  return percentage >= interval[0] && percentage <= interval[1]
}

const defaultColor = 'green'

export default (percentage:number) => (
  Object.keys(colors).find((color:string) =>
    isInInterval(percentage, colors[color as ObjectKey])) || defaultColor
)