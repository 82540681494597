import React, { memo } from 'react'

import '../styles/Welcome.scss'

function Privacy() {
  return (
    <section className="welcome">
            <div id="ot-sdk-cookie-policy" />
    </section>
  )
}

export default memo(Privacy)

Privacy.propTypes = {}
