export const packageWSMessage = (data: { operation: string }) => {
  const payload = {
    data,
  }
  return JSON.stringify(payload)
}

export const unpackWsMessage = (data: string) => {
  return JSON.parse(data)
}

export const isWsClosed = (ws: any) => ws && ws.readyState === WebSocket.CLOSED

/**
 * @class
 * @description
 * Tries to connect to the websocket server.
 * @param {string} token - Token de conexão com o dispenser.
 * @returns Promise with returns a WebSocket object.
 */
export default class WebSocketManager {
  constructor(token: string) {
    return new Promise((resolve, reject) => {
      const wsAddr = `${process.env.REACT_APP_WSS_URL}?token=${token}`
      const ws = new WebSocket(wsAddr)

      /**
       * @event
       * @description
       * Websocket onopen event listener.
       */
      ws.onopen = () => resolve(ws)

      /**
       * @event
       * @description
       * Websocket onclose event listener.
       */
      ws.onclose = err => reject(err)

      /**
       * @event
       * @description
       * Websocket onerror event listener.
       */
      ws.onerror = err => reject(err)
    })
  }
}
