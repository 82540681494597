import { HolderType, DispenserType } from '../types/Components'

const isLiteralHolderName = (text: string) => text.length > 1


const getSortableObject = (str: string) => {
  const textExp = str.match(/(\D+)/g)
  const numberExp = str.match(/(\d+)/g)
  const text = (str && textExp && textExp[0]) || ''
  const number = (str && numberExp && numberExp[0]) || '0'
  const literalHolderName = isLiteralHolderName(text)
  return { text, number, literalHolderName }
}

const sortByHolderName = (a: HolderType, b: HolderType) => {
  const sa = getSortableObject(a.holderName)
  const sb = getSortableObject(b.holderName)

  if (!sa.literalHolderName && !sb.literalHolderName) {
    const textComparison = sa.text.localeCompare(sb.text)

    if (textComparison !== 0) {
      return textComparison
    }
    return parseInt(sa.number, 10) - parseInt(sb.number, 10)
  }
  if (sa.literalHolderName && sb.literalHolderName) {
    return sa.text.localeCompare(sb.text)
  }
  return sa.literalHolderName ? 1 : -1
}


export const sortHolders = (arr: HolderType[]) =>
  arr.length ? arr.sort((a, b) => sortByHolderName(a, b)) : arr

export const sortDispensers = (dispensers: DispenserType[]) => (
  dispensers.length ? dispensers.sort((a, b) =>
    Number(b.crewSupport) - Number(a.crewSupport)) : dispensers
)