import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import '../styles/VolumeLevelBarLabel.scss'
import { VolumeLevelBarLabelType } from '../types/Components'

function VolumeLevelBarLabel({ hasRemaining, remainingContent }: VolumeLevelBarLabelType) {

  const { t } = useTranslation()

  return (
		<p className="volume-level-bar-label">
			{hasRemaining &&
				<>
					<Trans i18nKey="components:remaining" remainingContent={remainingContent}>{{ remainingContent }} <span className="volume-level-bar-label-remaining">remaining</span></Trans>
				</>
			}
			{!hasRemaining && t('components:available')}
		</p>
  )
}

export default memo(VolumeLevelBarLabel)
VolumeLevelBarLabel.defaultProps = {
  hasRemaining: false,
  remainingContent: '',
}
VolumeLevelBarLabel.propTypes = {
  hasRemaining: PropTypes.bool,
  remainingContent: PropTypes.string,
}
