import i18n from '../i18n'

import {
  ErrorsType,
  ComponentTypeEnum,
  DispenserListErrorTypeEnum,
  DispenserViewErrorTypeEnum,
  ScanViewErrorTypeEnum,
  ErrorTypesType,
  VariablesType,
  ErrorInfoType,
} from '../types/Errors'

const errors: ErrorsType = {
  [ComponentTypeEnum.DispenserList]: {
    [DispenserListErrorTypeEnum.ERROR_404]: {
      'message': i18n.t('error:idInDatabase'),
      'title': ({ outletId }: VariablesType) => `${i18n.t('error:cantFindOutlet')} ${ outletId }`,
    },
    [DispenserListErrorTypeEnum.ERROR_401]: {
      'message': i18n.t('error:unauthorized'),
      'title': i18n.t('error:errorMessage'),
    },
    [DispenserListErrorTypeEnum.ERROR_500]: {
      'message': ({ message }: VariablesType) => message as string,
      'title': i18n.t('error:errorMessage'),
    },
  },
  [ComponentTypeEnum.DispenserView]: {
    [DispenserViewErrorTypeEnum.ERROR_NO_STATUS_CODE]: {
      'message': i18n.t('error:statusDipenserNotAvailable'),
      'title': ({ serialNumber }: VariablesType) => `${i18n.t('error:cantFind')} ${serialNumber}`,
    },
    [DispenserViewErrorTypeEnum.ERROR_404]: {
      'message': i18n.t('error:isSerialNumberValid'),
      'title': ({ serialNumber }: VariablesType) => `${i18n.t('error:cantFindDispenser')} ${serialNumber}`,
    },
    [DispenserViewErrorTypeEnum.ERROR_401]: {
      'message': i18n.t('error:unauthorized'),
      'title': i18n.t('error:errorMessage'),
    },
    [DispenserViewErrorTypeEnum.ERROR_500]: {
      'message': ({ message }: VariablesType) => message as string,
      'title': i18n.t('error:errorMessage'),
    },
  },
  [ComponentTypeEnum.ScanView]: {
    [ScanViewErrorTypeEnum.ERROR_404]: {
      'message': i18n.t('error:isDispenserEnrroled'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:outletDataNotFound')}(${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_1000]: {
      'message': i18n.t('error:scanNewQRCode'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:deviceLostConectivity')}(${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_1006]: {
      'message': i18n.t('error:scanNewQRCode'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:connectionToDispenserClosed')}(${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_4000]: {
      'message': i18n.t('error:connectivityLostTryAgain'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:internalServerError')} (${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_4001]: {
      'message': i18n.t('error:scanNewQRCode'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:internalServerError')}(${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_4002]: {
      'message': i18n.t('error:connectivityLostTryAgain'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:unknownError')}(${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_4003]: {
      'message': i18n.t('error:scanNewQRCode'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:cannotConnectToDispenser')} (${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_5000]: {
      'message': i18n.t('error:scanNewQRCode'),
      'title': ({ code }: VariablesType) => `${i18n.t('error:connectionHasTimedOut')} (${code})`,
    },
    [ScanViewErrorTypeEnum.ERROR_CAMERA]: {
      'message': i18n.t('error:allowBrowserToAccessCamera'),
      'title': i18n.t('error:unableToAccessCamera'),
    },
  },
}

export default errors

type GetComponentErrorFn = (
  component: ComponentTypeEnum,
  type:ErrorTypesType,
) => ErrorInfoType | null

export const getComponentError:GetComponentErrorFn = (component, type) => {
  const isValidComponent:boolean = Object.values(ComponentTypeEnum).includes(component)

  if (!isValidComponent) return null

  const componentErrors = errors[component]

  return componentErrors[type]
}