/* eslint-disable @typescript-eslint/default-param-last */
import initialState from '../state'
import ReducerFactory from './ReducerFactory'
import { AppReducerAction, GlobalAppReducer } from '../types'

const appReducer: GlobalAppReducer = (
  state = initialState,
  action: AppReducerAction,
) => {
  const reducer = ReducerFactory.getReducer(action)

  return reducer ? reducer.newState(state) : state
}

export default appReducer
