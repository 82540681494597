/* eslint-disable react/jsx-no-bind */
import { memo, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import QrReader from 'react-qr-reader'
import { useTranslation } from 'react-i18next'

// Components
import Button from './Button'
import Error from './Error'

// Utils and Types
import {
  getOutletId,
  getToken,
} from '../utils/touchless-util'
import { buildError } from '../utils/ws-error-util'
import {
  getQueryParameters,
  isDebugMode,
  ERROR_QS_PARAMETER,
  TOKEN_QS_PARAMETER,
} from '../utils/debug-util'
import { useStore } from '../store/StoreProvider'
import { setError } from '../store/reducer/reducers/setError'
import { updateTokenAction } from '../store/reducer/reducers/updateTokenAction'
import { setInitialState } from '../store/reducer/reducers/setInitialState'
import { refreshAction } from '../store/reducer/reducers/refreshAction'
import { AppState } from '../store/types'
import initialState from '../store/state'
import { ComponentTypeEnum } from '../types/Errors'

// Assets
import Logo from '../images/header-logo.svg'

// Styles
import '../styles/ScanView.scss'

const getInitialState = (queryParams: URLSearchParams) => {
  const debug = isDebugMode(queryParams)
  const token = queryParams.has(TOKEN_QS_PARAMETER) ? queryParams.get(TOKEN_QS_PARAMETER) : null

  const { REACT_APP_PROD } = process.env
  const isProd = REACT_APP_PROD === 'true'

  if (isProd) {
    return token ? {
      loading: Boolean(token),
      token,
    } as AppState : initialState
  }

  return debug ? {
    error: queryParams.has(ERROR_QS_PARAMETER)
      ? buildError(queryParams.get(ERROR_QS_PARAMETER) as string)
      : null,
    loading: Boolean(token),
    token,
  } as AppState : initialState
}

function ScanView() {
  const { t } = useTranslation()
  const { search } = useLocation()
  const queryParams = getQueryParameters(search)
  const [state, dispatch] = useStore()

  const qrError = () => {
    dispatch(setError(buildError('camera')))
  }

  const onScan = (url: string | null) => {
    // e.g.: url => https://kots.0fs.io/?t=0C1DA4AE-6639-47BA-8AE2-1DBB98502F89_53013934
    const token = url ? getToken(url) : null
    if (token) {
      dispatch(updateTokenAction(token))
    }
  }

  const { connectionClosed, error, loading, outletId, token, crewAppToken } = state

  useEffect(() => {
    dispatch(setInitialState(getInitialState(queryParams)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Mounted treatment suggested here: https://www.debuggr.io/react-update-unmounted-component/
    let componentMounted = true
    const fetchOutlet = async () => {
      if (token && componentMounted && crewAppToken) {
        await getOutletId(token, dispatch)
      }
    }
    fetchOutlet()
    return () => { componentMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, state.crewAppToken])

  const redirectToOutletPage = outletId && connectionClosed
  const showQrCodeScanner = !error && !loading && !outletId

  const scanClickHandler = () => {
    dispatch(refreshAction())
  }

  return (
    <section className={`scan-view ${error ? 'scan-view--error' : ''}`}>
        {error &&
          <div className="scan-view-flex-spacer" />
        }
        {showQrCodeScanner &&
          <>
            <QrReader
              className="scan-view-qr-code"
              delay={150}
              facingMode='environment'
              onError={qrError}
              onScan={onScan}
            />
            <h4 className="scan-view-text">{t('components:scanningForQRCode')}</h4>
          </>
        }
        {loading &&
          <div className="scan-view-loading">
            <img src={Logo} className="scan-view-loading-logo" aria-hidden="true" alt="Logo" />
            <h4 className="scan-view-text scan-view-loading-text">{t('components:connectingToDispenser')}</h4>
          </div>
        }
        {error &&
          <>
            <Error {...error} component={ComponentTypeEnum.ScanView} />
            <div className="scan-view-flex-spacer" />
            <img src="/images/welcome/background.svg" className="scan-view-bg" aria-hidden="true" alt={t('basic:scan')} />
            <div className="scan-view-flex-spacer scan-view-flex-spacer--bottom">
              <div className="scan-view-button-container">
                <Button clickHandler={scanClickHandler} text={t('basic:scanQRCode')} />
                <a className="scan-view-about-link" href="https://www.coca-colafreestyle.com" rel="noopener noreferrer" target="_blank">{t('components:aboutFreestyle')}</a>
              </div>
            </div>
          </>
        }
        {redirectToOutletPage &&
          <Redirect to={`/portal/outlet/${outletId}`} />
        }
    </section>
  )
}

export default memo(ScanView)

ScanView.propTypes = {}
