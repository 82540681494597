import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { saveCrewAppToken } from '../store/reducer/reducers/saveCrewAppToken'
import { setError } from '../store/reducer/reducers/setError'
import { useStore } from '../store/StoreProvider'
import { CREW_APP_TOKEN } from '../utils/queries'
import { CREW_APP_TOKEN_COOKIE } from '../utils/touchless-util'
import { buildError } from '../utils/ws-error-util'

export default function useCrewAppToken(touchlessToken?: string) {
  const [ cookies, setCookies ] = useCookies([CREW_APP_TOKEN_COOKIE])
  const [registerCrewAppToken, { loading, error, data }] = useMutation(CREW_APP_TOKEN)
  const [crewAppToken, setCrewAppToken] = useState<string | undefined>()
  const [, dispatch] = useStore()

  const ONE_SECOND_IN_MS = 1000

  type NewCookie = { crewAppToken: string, ttl: number }

  const saveTokenToCookies = ({ crewAppToken: token, ttl }: NewCookie) => {
    const timeRemaining = new Date(ttl * ONE_SECOND_IN_MS)
    setCookies(CREW_APP_TOKEN_COOKIE, token, { expires: timeRemaining })
  }

  useEffect(() => {
    if (touchlessToken) {
      registerCrewAppToken({ variables: {
        touchlessToken,
        crewAppToken: cookies?.[CREW_APP_TOKEN_COOKIE],
      } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchlessToken])

  useEffect(() => {
    if (data) {
      const dataRegisterCrewAppToken = data.registerCrewAppToken
      dispatch(saveCrewAppToken(dataRegisterCrewAppToken.crewAppToken))
      setCrewAppToken(dataRegisterCrewAppToken.crewAppToken)
      saveTokenToCookies(dataRegisterCrewAppToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (error) {
      // Valid words for an error message that could be returned
      const LIMIT_REACHED_REGEX = /reached|limit/gm

      const message = error.graphQLErrors.find(err =>
        err.message.toLowerCase().match(LIMIT_REACHED_REGEX),
      )?.message
      dispatch(setError(buildError('4003', message)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (!touchlessToken && cookies?.[CREW_APP_TOKEN_COOKIE]) {
    return  { loading, error, crewAppToken: cookies?.[CREW_APP_TOKEN_COOKIE] }
  }

  return { loading, error, crewAppToken }
}
