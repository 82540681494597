import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import '../styles/SubNavItem.scss'
import { SubNavItemType } from '../types/Components'

function SubNavItem({ label, selected, url }: SubNavItemType) {
  return (
		<li className={`sub-nav-item ${selected ? 'sub-nav-item--selected' : ''}`}>
			<Link className="sub-nav-item-link" to={url}>
				<span className="sub-nav-item-label">{label}</span>
			</Link>
		</li>
  )
}

export default memo(SubNavItem)
SubNavItem.defaultProps = {
  selected: false,
}
