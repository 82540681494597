import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_DISPENSERS_FOR_OUTLET } from '../utils/queries'
import { getErrorType } from '../utils/error-messages-util'
import { sortDispensers } from '../utils/sort-util'

import DispenserCard from './DispenserCard'

import Error from './Error'
import LoaderConfig from '../utils/loader-default-config'

import '../styles/DispenserList.scss'

import { DispenserType } from '../types/Components'
import { ComponentTypeEnum, ErrorTypesType } from '../types/Errors'

const checkError = (isLoadingFirstTime:boolean, error:boolean, hasDispenserList:boolean):boolean =>
  !isLoadingFirstTime && (error || !hasDispenserList)

const getComponentError = (message?:string, outletId?:string) => {
  return {
    type: message ? (getErrorType(message) || '500') as ErrorTypesType : '404' as ErrorTypesType,
    component: ComponentTypeEnum.DispenserList,
    variables: message ? { message } : { outletId },
  }
}

function DispenserList() {
  const { t } = useTranslation()
  const { outletId } = useParams<{ outletId: string }>()
  const [firstLoad, setFirstLoad] = useState(false)
  const [getDispenserList, { loading: isLoading, error, data }] =
    useLazyQuery(GET_DISPENSERS_FOR_OUTLET, {
      variables: {
        outletId,
      },
    })

  useEffect(() => {
    getDispenserList()
    setFirstLoad(true)
  }, [getDispenserList, setFirstLoad])

  const dispenserList: DispenserType[] =
    data && data.listDispensersFor ? sortDispensers(data.listDispensersFor.slice()) : []
  const hasDispenserList = !!(dispenserList && dispenserList.length)
  const isLoadingFirstTime = firstLoad && isLoading

  const hasError = checkError(isLoadingFirstTime, !!error, hasDispenserList)

  let componentError = null

  if (hasError) {
    componentError = getComponentError(error?.message, outletId)
  }

  return (
    <section className='dispenser-list'>
      {isLoading &&
        <>
          <ContentLoader
            viewBox="0 0 343 72"
            speed={2}
            {...LoaderConfig}>
            <rect x="8" y="32" rx="2" ry="2" width="172" height="16" />
          </ContentLoader>
          <DispenserCard loading key={0} />
          <DispenserCard loading key={1} />
          <DispenserCard loading key={2} />
        </>
      }
      {!isLoadingFirstTime && hasDispenserList &&
        <>
          <h3 className="dispenser-list-title">{t('basic:myDispensers')}</h3>
          {
            dispenserList.map(dispenser =>
              <DispenserCard dispenser={dispenser} key={dispenser.serialNumber} />,
            )
          }
        </>
      }
      {componentError &&
        <Error {...componentError} />
      }
    </section>
  )
}

export default memo(DispenserList)
DispenserList.propTypes = {}
