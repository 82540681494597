import DispenserPlatformEnum from './dispenser-type-enum'
import Enum from './enum-util'

export function getDispenserType(type:string) {
  const isValidType = Enum.containsValue<typeof DispenserPlatformEnum>(DispenserPlatformEnum, type)
  return isValidType && type
}

export function getDispenserImage(type:string) {
  const dispenserType = getDispenserType(type)
  return dispenserType ? `/images/dispenser/${dispenserType}.svg` : null
}