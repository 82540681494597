import { ErrorPropsType } from '../types/Errors'

export const buildError = (type: string, message?: string) => {
  return {
    type,
    variables: {
      code: type,
      message,
    },
  } as ErrorPropsType
}

type FuncType = ((code: string) => ErrorPropsType)

type ErrorType = {
  'Internal server error': ErrorPropsType,
  'Another Error': FuncType,
  'default': ErrorPropsType
}
const errors = {
  'Internal server error': buildError('4000'),
  'Another Error': (code: string) => buildError(code || '4001'),
  'default': buildError('4002'),
} as ErrorType

type ObjectKey = keyof typeof errors

export default function getWSErrorFromMessage(code: string, message: string) {
  let func
  switch (typeof errors[message as ObjectKey]) {
    case 'function':
      func = errors[message as ObjectKey] as FuncType
      return func(code)
    case 'object':
      return errors[message as ObjectKey]
    default:
      return errors.default
  }
}