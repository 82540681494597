import React, { memo } from 'react'

import { useRouteMatch } from 'react-router-dom'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'
import LoaderConfig from '../utils/loader-default-config'
import { dispenserDashboard } from '../utils/routes'
import SubNavItem from './SubNavItem'

import '../styles/SubNav.scss'

function SubNav() {
  const { t } = useTranslation()
  const match = useRouteMatch(dispenserDashboard)
  const loading = false
  return (
		<nav>
			<ul className="sub-nav">
				{loading  &&
					<ContentLoader
						viewBox="0 0 375 32"
						speed={2}
						{...LoaderConfig}>
						<rect x="16" y="0" rx="2" ry="2" width="104" height="16" />
						<rect x="136" y="0" rx="2" ry="2" width="115" height="16" />
						<rect x="267" y="0" rx="2" ry="2" width="64" height="16" />
						<rect x="347" y="0" rx="2" ry="2" width="81" height="16" />
					</ContentLoader>
				}
				{(!loading && match?.url) &&
					<SubNavItem label={t('basic:dashboard')} url={match.url} selected/>
				}
			</ul>
		</nav>
  )
}

export default memo(SubNav)
SubNav.defaultProps = {}
SubNav.propTypes = {}
