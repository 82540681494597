import { closeAction } from '../store/reducer/reducers/closeAction'
import { setError } from '../store/reducer/reducers/setError'
import { updateOutletAction } from '../store/reducer/reducers/updateOutletAction'
import { ErrorPropsType } from '../types/Errors'
import WsClient, { isWsClosed, packageWSMessage, unpackWsMessage } from './ws-client'
import getWSErrorFromMessage, { buildError } from './ws-error-util'

export const getToken = (url: string) => {
  console.log(`getToken() Checking Token for url=${url}`)
  const tokenMatch = url.match('([A-F0-9]+-[A-F0-9]+-[A-F0-9]+-[A-F0-9]+-[A-F0-9]+_[0-9]{8})')
  const token = tokenMatch ? tokenMatch[1] : null
  console.log(`getToken() Retrieved token=${token}`)
  return token
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

// constants
export const CREW_APP_TOKEN_COOKIE = 'cTk'

const WS_TIMEOUT = 30 /* s */ * 1000 /* ms */ /* a 60s websocket timeout */

// function that closes Ws if it is open and timed out
async function timeoutClose(ws: any) {
  await sleep(WS_TIMEOUT)
  if (ws && !isWsClosed) {
    ws.close()
  }
}

export const getOutletId = async (token: string, dispatch: React.Dispatch<any>) => {
  // TODO: This method will be refactor or remove. According FSCTP-2935 ticket.
  let ws: any
  try {
    ws = await new WsClient(token)
    ws.onclose = () => {
      console.log('Websocket closed connection')
      dispatch(closeAction())
    }
    ws.onmessage = (msg: any) => {
      const msgData = unpackWsMessage(msg.data)
      if (msgData && msgData.type === 'dispenserCrewData') {
        ws.close()
        const outlet = msgData.data.outletId
        if (outlet) {
          dispatch(updateOutletAction(outlet as string))
          return
        }
        dispatch(setError(buildError('404')))
      } else if (msgData.type === undefined || msgData.type === 'undefined') {
        ws.close()
        const { code, message } = msg.data || {}
        const error = getWSErrorFromMessage(code, message)
        dispatch(setError(error as ErrorPropsType))
      }
    }
    ws.send(packageWSMessage({ operation: '/dispenser/crew-data' }))
    timeoutClose(ws)
  } catch (err) {
    dispatch(setError(buildError('4003')))
    if (ws && !isWsClosed) {
      ws.close()
    }
  }
}
