import { ApolloLink, createHttpLink, InMemoryCache, ApolloClient } from '@apollo/client'
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link'

const {
  REACT_APP_GRAPHQL_API_REGION,
  REACT_APP_GRAPHQL_API_URL,
} = process.env

/**
 * @description creates new apolloClient for the provider
 * @param {string} token - This attribute is mandatory and its value is the crewAppToken
 * @returns a new ApolloClient to be used in the ApolloProvider
 */
export default function createClient(token: StringConstructor) {
  const auth = {
    type: 'AWS_LAMBDA',
    token,
  } as AuthOptions
  const httpLink = createHttpLink({ uri: REACT_APP_GRAPHQL_API_URL })
  const link = ApolloLink.from([
    createAuthLink({
      url: REACT_APP_GRAPHQL_API_URL as string,
      region: REACT_APP_GRAPHQL_API_REGION as string,
      auth,
    }) as unknown as ApolloLink,
    httpLink,
  ])
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })


}