/* eslint-disable import/prefer-default-export */
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'
import { ErrorPropsType } from '../../../types/Errors'

export class SetErrorReducer extends AppReducer {
  newState(state: AppState): AppState {
    return {
      ...state,
      loading: false,
      error: this.action.payload as ErrorPropsType,
    }
  }
}

export function setError(payload: ErrorPropsType): AppReducerAction {
  return { type: ReducerType.ERROR_ACTION, payload }
}