export enum ComponentTypeEnum {
  DispenserList = 'dispenserList',
  DispenserView = 'dispenserView',
  ScanView = 'scanView',
}

export enum DispenserListErrorTypeEnum {
  ERROR_401 = '401',
  ERROR_404 = '404',
  ERROR_500 = '500',
}

export enum DispenserViewErrorTypeEnum {
  ERROR_401 = '401',
  ERROR_404 = '404',
  ERROR_500 = '500',
  ERROR_NO_STATUS_CODE = 'none',
}

export enum ScanViewErrorTypeEnum {
  ERROR_404 = '404',
  ERROR_1000 = '1000',
  ERROR_1006 = '1006',
  ERROR_4000 = '4000',
  ERROR_4001 = '4001',
  ERROR_4002 = '4002',
  ERROR_4003 = '4003',
  ERROR_5000 = '5000',
  ERROR_CAMERA = 'camera',
}

export type ErrorTypesType =
  (DispenserListErrorTypeEnum | DispenserViewErrorTypeEnum | ScanViewErrorTypeEnum)

export type VariablesType = {
  message?: string,
  outletId?: string,
  serialNumber?: string,
  code?: string,
}

export interface ErrorPropsType {
  type: ErrorTypesType
  component: ComponentTypeEnum
  variables: VariablesType
}

export type ProcessedError = ((errorProps: VariablesType ) => string) | string

export type ErrorInfoType = {
  [key: string]: ProcessedError,
  message: ProcessedError,
  title: ProcessedError
}

type DispenserListErrorType = {
  [key: string]: ErrorInfoType,
  [DispenserListErrorTypeEnum.ERROR_404]: ErrorInfoType,
  [DispenserListErrorTypeEnum.ERROR_401]: ErrorInfoType,
  [DispenserListErrorTypeEnum.ERROR_500]: ErrorInfoType,
}
type DispenserViewErrorType = {
  [key: string]: ErrorInfoType,
  [DispenserViewErrorTypeEnum.ERROR_NO_STATUS_CODE]: ErrorInfoType,
  [DispenserViewErrorTypeEnum.ERROR_404]: ErrorInfoType,
  [DispenserViewErrorTypeEnum.ERROR_401]: ErrorInfoType,
  [DispenserViewErrorTypeEnum.ERROR_500]: ErrorInfoType,
}
type ScanViewErrorType = {
  [key: string]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_404]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_1000]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_1006]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_4000]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_4001]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_4002]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_4003]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_5000]: ErrorInfoType,
  [ScanViewErrorTypeEnum.ERROR_CAMERA]: ErrorInfoType,
}

export type ErrorsType = {
  dispenserList:DispenserListErrorType,
  dispenserView: DispenserViewErrorType,
  scanView: ScanViewErrorType
}

export type ComponentErrorPropsType = {
  type: string,
  title?: string,
  message?: string,
}