import React, { memo } from 'react'
import PropTypes from 'prop-types'

import '../styles/Button.scss'
import { ButtonType } from '../types/Components'

function Button({ clickHandler, text }: ButtonType) {
  return (
		<button className="button" type="button" onClick={clickHandler}>
			{text}
		</button>

  )
}

export default memo(Button)
Button.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}
