import { gql } from '@apollo/client'

export const GET_DISPENSERS_FOR_OUTLET = gql`
	query listDispensersFor($outletId: String!) {
		listDispensersFor(outletId: $outletId) {
			dispenserName
			crewSupport
			serialNumber
			type
		}
	}
`
// TODO - Add new query to this field
export const GET_DISPENSER_STATE_FOR_DISPENSER = gql`
query getDispenser($serialNumber: String!) {
	getDispenser(serialNumber: $serialNumber) {
		holders {
			currentVolume
			holderName
			doubleCartridge
			available
			ingredientId
			ingredientName
			issueType
			maxVolume
			percentageGaugeLevel
			sliceCount
			timeRemaining
			timeRemainingRounded
			volumeLevelAvailable
		}
	}
}`

export const CREW_APP_TOKEN = gql`
mutation registerCrewAppToken($touchlessToken: String!, $crewAppToken: String) {
    registerCrewAppToken(touchlessToken: $touchlessToken, crewAppToken: $crewAppToken) {
		crewAppToken
		outletId
		ttl
	  }
	}`
