/* eslint-disable import/prefer-default-export */
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'

export class SetInitialStateReducer extends AppReducer {
  newState(state: AppState): AppState {
    return {
      ...state,
      ...this.action.payload as AppState,
    }
  }
}

export function setInitialState(payload: AppState): AppReducerAction {
  return { type: ReducerType.SET_INITIAL_STATE, payload }
}