import { memo } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

// Components
import HeaderContent from './HeaderContent'

// Utils and Types
import { dispenserDashboard, home, outletDispensers } from '../utils/routes'

// Styles
import '../styles/Header.scss'

function Header() {
  const match = useRouteMatch<{ serialNumber?: string }>(dispenserDashboard)
  const matchSerialNumber = (match?.params?.serialNumber) as string
  return (
    <Switch>
      <Route
        exact
        path={dispenserDashboard}
        render={() =>
          <HeaderContent
            hasTopNav
            title={matchSerialNumber}
          />
        }
      />
      <Route path={outletDispensers} component={HeaderContent} />
      <Route path={home} />
    </Switch>
  )
}

export default memo(Header)
Header.propTypes = {}
