import React, { useState, useEffect }  from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import Main from './components/Main'
import Header from './components/Header'
import Page from './components/Page'
import useCrewAppToken from './hooks/useCrewAppToken'
import createClient from './utils/create-client-util'

import './App.scss'
import { useStore } from './store/StoreProvider'

import { version } from '../package.json'

function App() {
  console.log(`Application version: ${version}`)
  const ONETRUST_SCRIPT_URL = process.env.REACT_APP_ONETRUST_URL
  const ONETRUST_DOMAIN_SCRIPT = process.env.REACT_APP_ONETRUST_DOMAIN_SCRIPT

  const [hasLoadedScript, setHasLoadedScript] = useState(false)
  const [store] = useStore()
  const { crewAppToken } = useCrewAppToken(store.token)

  const client = createClient(crewAppToken)

  // Loading OneTrust SDK
  useEffect(() => {
    const script = document.createElement('script')
    script.src = ONETRUST_SCRIPT_URL
    script.setAttribute('data-domain-script', ONETRUST_DOMAIN_SCRIPT)
    script.crossOrigin = 'anonymous'
    script.onload = () => setHasLoadedScript(true)
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Dynamic import after OneTrust Script is Loaded

  useEffect(() => {
    // global window
    if (hasLoadedScript === true && window.Optanon !== undefined) {
      import('./utils/onetrust/optanonwrapper').then(opt => opt.default())
    } else if (hasLoadedScript === true && window.Optanon === undefined) {
      const intervalId = setInterval(() => {
        if (window.Optanon !== undefined) {
          import('./utils/onetrust/optanonwrapper').then(opt => opt.default())
          clearInterval(intervalId)
        }
      }, 500)
    }
  }, [hasLoadedScript])

  return (
		<ApolloProvider client={client}>
			<BrowserRouter>
				<div className="app">
					<Page>
						<Header/>
						<Main/>
					</Page>
				</div>
			</BrowserRouter>
		</ApolloProvider>
  )
}

export default App
