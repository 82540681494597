import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// Components
import HolderCard from './HolderCard'

// Utils and Types
import { sortHolders } from '../utils/sort-util'
import { HolderListType } from '../types/Components'

// Styles
import '../styles/HolderList.scss'

function HolderList({ holders, loading }: Readonly<HolderListType>) {
  const { t } = useTranslation()
  const NUMBER_OF_LOADING_ITEMS = 8
  // order by ingredient name
  // display only holders that have an ingredient
  const orderedHolders = holders?.length ? (
    sortHolders(holders.filter(holder => holder.ingredientId))
  ) : []
  const hasItems = orderedHolders && orderedHolders.length > 0
  const hasHolders = Boolean(!loading && hasItems)
  const hasNoHolders = Boolean(!loading && !hasItems)

  return (
		<section className="holder-list">
			{loading &&
				Array.from(Array(NUMBER_OF_LOADING_ITEMS).keys()).map(idx =>
					<HolderCard loading key={idx}/>,
				)
			}
			{hasHolders &&
				orderedHolders.map((holder) =>
					<HolderCard holder={holder} key={`${holder.ingredientName}${holder.holderName}${holder.timeRemainingRounded}`}/>,
				)
			}
			{hasNoHolders &&
				<p className="holder-list-empty-message">{t('components:noHoldersFound')}</p>
			}
		</section>
  )
}

export default memo(HolderList)
HolderList.defaultProps = {
  holders: [],
  loading: false,
}
HolderList.propTypes = {
  holders: PropTypes.array,
  loading: PropTypes.bool,
}
