import React, { memo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { home, portal, scan, privacy } from '../utils/routes'
import Portal from './Portal'
import ScanView from './ScanView'
import Welcome from './Welcome'
import Privacy from './Privacy'

import '../styles/Main.scss'

function Main() {
  return (
		<main className="main">
			<Switch>
				<Route path={portal} component={Portal}/>
				<Route path={scan} component={ScanView}/>
				<Route path={privacy} component={Privacy}/>
				<Route exact path={home} component={Welcome}/>
				<Route>
					<Redirect to={home}/>
				</Route>
			</Switch>
		</main>
  )
}

export default memo(Main)

Main.propTypes = {}
