import React from 'react'
import ReactDOM from 'react-dom'

import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'
import * as serviceWorker from './serviceWorker'

import App from './App'
import './index.css'
import './i18n'
import StoreProvider from './store/StoreProvider'
import createClient from './utils/create-client-util'
import appReducer from './store/reducer'
import initialState from './store/state'

const { REACT_APP_AUTHORIZATION_TOKEN } = process.env

const client = createClient(REACT_APP_AUTHORIZATION_TOKEN)

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<StoreProvider reducer={appReducer} initialState={initialState} >
				<CookiesProvider>
					<App />
				</CookiesProvider>
			</StoreProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
