import React, { memo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import DispenserList from './DispenserList'
import DispenserView from './DispenserView'
import { dispenserDashboard, outletDispensers, home } from '../utils/routes'

function Portal() {
  return (
		<Switch>
			<Route path={dispenserDashboard} component={DispenserView}/>
			<Route exact path={outletDispensers} component={DispenserList}/>
			<Route>
				<Redirect to={home}/>
			</Route>
		</Switch>
  )
}

export default memo(Portal)

Portal.propTypes = {}
