import {
  VariablesType,
  ProcessedError,
} from '../types/Errors'

const processVar = (property: ProcessedError, variables: VariablesType ) => typeof property === 'function' ? property(variables) : property

class ErrorMessage {
  private errorTitle: string

  private errorMessage: string

  constructor(title:ProcessedError, message:ProcessedError, variables: VariablesType) {
    this.errorTitle = processVar(title, variables)
    this.errorMessage = processVar(message, variables)
  }

  get title() {
    return this.errorTitle
  }

  get message() {
    return this.errorMessage
  }
}

export default ErrorMessage