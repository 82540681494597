import { AppReducerAction, AppState } from '../types'

export default abstract class AppReducer {
  action: AppReducerAction

  constructor(action: AppReducerAction) {
    this.action = action
  }

  abstract newState(state: AppState): AppState
}