/* eslint-disable import/prefer-default-export */
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'

export class UpdateTokenActionReducer extends AppReducer {
  newState(state: AppState): AppState {
    return {
      ...state,
      loading: true,
      token: this.action.payload as string,
    }
  }
}

export function updateTokenAction(payload: string): AppReducerAction {
  return { type: ReducerType.UPDATE_TOKEN_ACTION, payload }
}