import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link, matchPath, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Components
import SubNav from './SubNav'

// Utils and Types
import { dispenserDashboard, home, outletDispensers } from '../utils/routes'
import { HeaderContentType } from '../types/Components'

// Assets
import Logo from '../images/header-logo.svg'
import BackButton from '../images/header-back-button.svg'

// Styles
import '../styles/HeaderContent.scss'

function HeaderContent({ hasTopNav, title }: Readonly<HeaderContentType>) {
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useLocation<{ dispenserName?: string }>()
  const dispenserDashboardMatch = useRouteMatch(dispenserDashboard)
  const dispenserTitle = state?.dispenserName ?? title

  function handleBackButtonClick() {
    if (dispenserDashboardMatch?.url) {
      const outletUrl = dispenserDashboardMatch.url.split('/dispenser/')[0]
      if (matchPath(outletUrl, { path: outletDispensers })) {
        history.push(outletUrl)
        return
      }
    }
    history.goBack()
  }

  return (
    <header className="header-content">
      <div className="header-content-container">
        <nav className="header-content-nav">
          {hasTopNav ? (
            <button
              type="button"
              className="header-content-back-button"
              onClick={handleBackButtonClick}
              aria-label={t('components:goBack')}
            >
              <img src={BackButton} alt={t('components:goBack')} />
            </button>
          ) : (
            <Link className="header-content-logo" to={home}>
              <img src={Logo} alt={t('basic:logo')} />
            </Link>
          )}
        {dispenserTitle &&
          <h2 className="header-content-title">{dispenserTitle}</h2>
        }
        </nav>
      </div>
      {hasTopNav && <SubNav />}
    </header>
  )
}

export default memo(HeaderContent)

HeaderContent.defaultProps = {
  hasTopNav: false,
  title: '',
}

HeaderContent.propTypes = {
  hasTopNav: PropTypes.bool,
  title: PropTypes.string,
}
