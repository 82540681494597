/* eslint-disable import/prefer-default-export */
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'

export class SaveCrewAppTokenReducer extends AppReducer {
  newState(state: AppState): AppState {
    return {
      ...state,
      crewAppToken: this.action.payload as string,
    }
  }
}

export function saveCrewAppToken(payload: string): AppReducerAction {
  return { type: ReducerType.SAVE_CREW_APP_TOKEN, payload }
}