import React, { memo } from 'react'
import PropTypes from 'prop-types'

import '../styles/VolumeLevelBarItem.scss'
import { VolumeLevelBarItemType } from '../types/Components'

function VolumeLevelBarItem({ color }: VolumeLevelBarItemType) {
  return (
		<div className={`volume-level-bar-chart-item ${color ? `volume-level-bar-chart-item--${color}` : ''}`}/>
  )
}

export default memo(VolumeLevelBarItem)
VolumeLevelBarItem.defaultProps = {
  color: '',
}
VolumeLevelBarItem.propTypes = {
  color: PropTypes.string,
}
