/**
 * @constant
 * @description
 * Path to the home.
 * @type {string}
 */
export const home: string = '/'

/**
  * @constant
  * @description
  * Path to the Portal component that gives access to some pages.
  * @type {string}
  */
export const portal: string = '/portal'

/**
  * @constant
  * @description
  * Path to the dashboard with the list of dispensers.
  * @type {string}
  */
export const outletDispensers: string = `${portal}/outlet/:outletId`

/**
  * @constant
  * @description
  * Path to the dispenser page
  * @type {string}
  */
export const dispenserDashboard: string = `${outletDispensers}/dispenser/:serialNumber`

/**
  * @constant
  * @description
  * Path to OneTrust Privacy Page
  * @type {string}
  */
export const privacy: string = '/privacy'

/**
  * @constant
  * @description
  * Path to the QR Code page
  * @type {string}
  */
export const scan: string = '/scan'

