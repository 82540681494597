enum DispenserPlatformEnum {
  TYPE_3100 = '3100',
  // TYPE_6000 = '6000',
  TYPE_7000 = '7000',
  TYPE_7100 = '7100',
  TYPE_8000 = '8000',
  TYPE_9000 = '9000',
  TYPE_9100 = '9100',
}

export default DispenserPlatformEnum