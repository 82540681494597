import AppReducer from './AppReducer'
import { AppReducerAction, ReducerType } from '../types'

// Reducers
import { CloseActionReducer } from './reducers/closeAction'
import { RefreshActionReducer } from './reducers/refreshAction'
import { SaveCrewAppTokenReducer } from './reducers/saveCrewAppToken'
import { SetErrorReducer } from './reducers/setError'
import { SetInitialStateReducer } from './reducers/setInitialState'
import { UpdateOutletActionReducer } from './reducers/updateOutletAction'
import { UpdateTokenActionReducer } from './reducers/updateTokenAction'

const reducerMap = {
  [ReducerType.CLOSE_ACTION]: CloseActionReducer,
  [ReducerType.REFRESH_ACTION]: RefreshActionReducer,
  [ReducerType.SAVE_CREW_APP_TOKEN]: SaveCrewAppTokenReducer,
  [ReducerType.ERROR_ACTION]: SetErrorReducer,
  [ReducerType.SET_INITIAL_STATE]: SetInitialStateReducer,
  [ReducerType.UPDATE_OUTLET_ACTION]: UpdateOutletActionReducer,
  [ReducerType.UPDATE_TOKEN_ACTION]: UpdateTokenActionReducer,
}

export default class ReducerFactory {
  static getReducer(action: AppReducerAction): AppReducer {
    return new reducerMap[action.type](action)
  }
}
