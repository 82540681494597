/* eslint-disable import/prefer-default-export */
import AppReducer from '../AppReducer'
import { AppReducerAction, AppState, ReducerType } from '../../types'

export class UpdateOutletActionReducer extends AppReducer {
  newState(state: AppState): AppState {
    return {
      ...state,
      loading: false,
      outletId: this.action.payload as string,
    }
  }
}

export function updateOutletAction(payload: string): AppReducerAction {
  return { type: ReducerType.UPDATE_OUTLET_ACTION, payload }
}