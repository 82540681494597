import { IssueTypeType } from '../types/Components'

/**
 * @type
 * @description
 * Type that contains the content of each IssueType.
 * @property {string} message - Status displayed on the dispenser dashboard.
 * @property {string} selector - CSS class modifier that contains the visual
 * changes for each status.
 */
type Issue = {
  message: string;
  selector: string;
}
/**
 * @enum
 * @description
 * Beverage availability status list.
 */

const issueTypes = {
  'NOT_AVAILABLE': {
    message: 'Not Available',
    selector: '--not-available',
  },
  'PRIME_TROUBLE': {
    message: 'Prime Required',
    selector: '--prime-needed',
  },
  'PURGE_TROUBLE': {
    message: 'Purge Required',
    selector: '--purge-needed',
  },
  'SOLD_OUT': {
    message: 'Sold Out',
    selector: '--soldout',
  },
}

export default (issueType: IssueTypeType) => {
  return issueTypes[issueType] as Issue
}
