import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

// Components
import VolumeLevelBar from './VolumeLevelBar'
import HolderIssueMessage from './HolderIssueMessage'

// Utils and Types
import LoaderConfig from '../utils/loader-default-config'
import { HolderCardType } from '../types/Components'
import getIssueObj from '../utils/issue-type-util'

// Styles
import '../styles/HolderCard.scss'

// eslint-disable-next-line complexity
function HolderCard({ holder, loading }: Readonly<HolderCardType>) {
  const issue = holder && getIssueObj(holder.issueType)
  return (
    <article className={`holder-card ${loading ? 'holder-card--loading' : ''}`}>
      <header className="holder-card-header">
        {(!loading && holder) &&
          <>
            <h4 className="holder-card-ingredient-name">{holder.ingredientName}</h4>
            <span className="holder-card-name">{holder.holderName}</span>
          </>
        }
      </header>
      <section className="holder-card-content">
        {(!loading && holder) &&
          <summary className="holder-card-content-summary">
            {issue &&
              <HolderIssueMessage
                selector={issue.selector}
                message={issue.message || holder.issueType} />
            }
            {!issue &&
              <VolumeLevelBar
                available={holder.available}
                volumeAvailable={holder.volumeLevelAvailable}
                percentage={holder.percentageGaugeLevel}
                timeRemainingRounded={holder.timeRemainingRounded} />
            }
          </summary>
        }
        {loading &&
          <ContentLoader
            viewBox="0 0 223 72"
            speed={2}
            {...LoaderConfig}>
            <rect x="16" y="16" rx="2" ry="2" width="114" height="8" />
            <rect x="16" y="52" rx="1" ry="1" width="191" height="4" />
          </ContentLoader>
        }
      </section>
    </article>
  )
}

export default memo(HolderCard)

HolderCard.defaultProps = {
  holder: {},
  loading: false,
}

HolderCard.propTypes = {
  holder: PropTypes.object,
  loading: PropTypes.bool,
}
