import React, { memo } from 'react'
import PropTypes from 'prop-types'

import VolumeLevelBarLabel from './VolumeLevelBarLabel'
import VolumeLevelBarItem from './VolumeLevelBarItem'
import getColor from '../utils/volume-level-util'

import '../styles/VolumeLevelBar.scss'
import { VolumeLevelBarType } from '../types/Components'

function VolumeLevelBar({
  available,
  percentage,
  volumeAvailable,
  timeRemainingRounded,
}: VolumeLevelBarType) {
  const numberOfBlks = 10
  const filledItemsArr = [...Array(Math.ceil(percentage / numberOfBlks)).keys()]
  const remainingArr = [...Array(numberOfBlks - filledItemsArr.length).keys()]
  const color = getColor(percentage)
  const remainingContent = timeRemainingRounded || `${percentage}%`
  return (
		<div className="volume-level-bar">
			<VolumeLevelBarLabel
				hasRemaining={volumeAvailable && available}
				remainingContent={remainingContent}
			/>
			<div className="volume-level-bar-bottom-container">
				{volumeAvailable &&
					<>
						<span className="volume-level-bar-percentage">{percentage}%</span>
						<div className="volume-level-bar-chart">
							{
								filledItemsArr.map((item) =>
									<VolumeLevelBarItem color={color} key={item}/>,
								)
							}
							{
								remainingArr.map((item) =>
									<VolumeLevelBarItem key={item}/>,
								)
							}
						</div>
					</>
				}
				{!volumeAvailable && available &&
					<div className="volume-level-bar-status volume-level-bar-status--green"/>
				}
			</div>
		</div>
  )
}

export default memo(VolumeLevelBar)

VolumeLevelBar.defaultProps = {
  available: false,
  percentage: 0,
  volumeAvailable: false,
  timeRemainingRounded: '',
}

VolumeLevelBar.propTypes = {
  available: PropTypes.bool,
  percentage: PropTypes.number,
  volumeAvailable: PropTypes.bool,
  timeRemainingRounded: PropTypes.string,
}
