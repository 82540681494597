import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { scan } from '../utils/routes'

import '../styles/Welcome.scss'
import '../styles/OneTrust.scss'

function Welcome() {
  const { t } = useTranslation()
  const history = useHistory()
  const scanClickHandler = () => history.push(scan)
  return (
		<section className="welcome">
			<div className="welcome-flex-spacer welcome-flex-spacer--top"/>
			<header className="welcome-header">
				<h2 className="welcome-title">{t('components:welcome')}</h2>
				<div className="welcome-description">
					<p className="welcome-description-text">{t('components:pleaseScanQRCode')}</p>
				</div>
			</header>
			<div className="welcome-flex-spacer"/>
			<img src="/images/welcome/background.svg" className="welcome-bg" aria-hidden="true" alt={t('components:welcome')}/>
			<div className="welcome-flex-spacer welcome-flex-spacer--bottom">
				<nav className="welcome-nav">
					{/* eslint-disable-next-line react/jsx-no-bind */}
					<Button clickHandler={scanClickHandler} text={t('basic:scanQRCode')}/>
					<a className="welcome-nav-about-link" href="https://www.coca-colafreestyle.com" rel="noopener noreferrer" target="_blank">{t('components:aboutFreestyle')}</a>
					<button id="ot-sdk-btn" className="ot-sdk-show-settings" type="button">{t('components:cookieSettings')}</button>
					<a className="welcome-nav-privacy" href="/privacy">{t('components:privacyPolicy')}</a>
				</nav>
			</div>
		</section>
  )
}

export default memo(Welcome)

Welcome.propTypes = {}
