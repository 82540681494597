/**
 * @enum
 * @default
 * @description
 * Default configuration of the load component.
 */
enum LoaderDefaultConfig {
  backgroundColor = '#2c2c2e',
  foregroundColor = '#3f4044',
  width = '100%',
}

export default LoaderDefaultConfig
