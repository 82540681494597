import React, { memo } from 'react'
import PropTypes from 'prop-types'

import '../styles/Page.scss'
import { PageType } from '../types/Components'

function Page({ children }: PageType) {
  return (
		<div className="page">
			{children}
		</div>
  )
}

export default memo(Page)
Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node), PropTypes.node,
  ]).isRequired,
}
